import React, { ReactNode } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { isLoggedVar } from '../../../infrastructure/apollo/cache';
import { apolloClient } from '../../../infrastructure/apollo/client';
import { DECODED_TOKEN, GET_ALL_MENUS, GET_MUTUAL_BY_ID } from './queries';
import { NewLayoutComponent } from '../../components/NewLayout';
import { getPermission } from '../../../infrastructure/utils/hooks/usePermission';
import { NoAuthorize } from '../../../infrastructure/common/noAuthorize';

const LayoutC: React.FC<{ children: ReactNode; path: string }> = ({ children, path }) => {
  const history = useNavigate();
  const userCharge = JSON.parse(localStorage.getItem('ucId') || '{}');

  const { data: dataGetAllMenu, loading: loadingMenu } = useQuery(GET_ALL_MENUS, {
    fetchPolicy: 'cache-and-network',
  });

  const token = localStorage.getItem('tokenAuth');

  const [getDecoded, { data, called, loading }] = useLazyQuery(DECODED_TOKEN);
  const { data: mutual } = useQuery(GET_MUTUAL_BY_ID);
  const [decoded, setDecoded] = React.useState<any>(null);

  // const decoded = data?.decodeUserToken || null;

  React.useEffect(() => {
    if (token) {
      getDecoded({ variables: { token }, fetchPolicy: 'network-only' });
    }
  }, [token]);

  React.useEffect(() => {
    if (data?.decodeUserToken && called && !loading) {
      setDecoded(data.decodeUserToken);
    }
  }, [data, called, loading]);

  const handleSession = () => {
    history('/');
    const colors = JSON.parse(localStorage.getItem('colorsTheme') || '{}');
    localStorage.clear();
    localStorage.setItem('colorsTheme', JSON.stringify(colors));
    apolloClient.cache.gc();
    isLoggedVar({ state: false, expired: false, code: '200', message: 'Ha cerrado sesión.' });
  };
  return (
    <NewLayoutComponent
      handleSession={handleSession}
      dataMenu={dataGetAllMenu?.menus}
      loadingMenu={loadingMenu}
      auditor={mutual?.getMutualById?.mAuditor}
      userCharge={userCharge}>
      {!getPermission({ ...decoded, path }) ? <NoAuthorize validation={decoded} /> : children}
    </NewLayoutComponent>
  );
};

export default LayoutC;
