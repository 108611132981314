/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Drawer, Skeleton, Typography, useTheme } from '@mui/material';
import { BusinessCenter, Home } from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import LabelIcon from '@mui/icons-material/Label';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GroupsIcon from '@mui/icons-material/Groups';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import StoreIcon from '@mui/icons-material/Store';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import WidgetsIcon from '@mui/icons-material/Widgets';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DvrIcon from '@mui/icons-material/Dvr';
import { TNewAside } from './types.d';
import { MenuOptionV } from './MenuOption';

type TAside = {
  width?: string;
  height?: string;
  bgColor?: string;
  isOpen?: boolean;
  widthCollapsed?: string;
  widthExpanded?: string;
  marginTop: string;
};

const Aside = styled.aside<TAside>`
  background: ${({ bgColor }) => bgColor || '#eee'};
  width: ${({ isOpen, widthCollapsed, widthExpanded }) =>
    isOpen ? widthExpanded : widthCollapsed};
  height: 100%;
  padding-top: 2px;
  margin-top: ${({ marginTop }) => marginTop || '0px'};
  /* overflow-y: scroll; */
  overflow: auto;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(0px)')};
  transition:
    transform 0.3s ease-in-out,
    width 0.3s ease-in-out; /* Agrega una transición suave al transform y la anchura */
`;

type TMenuOptionLink = {
  width?: string;
  height?: string;
  bgColor?: string;
  isOpen?: boolean;
  widthCollapsed?: string;
  widthExpanded?: string;
};

const MenuOptionLink = styled.div<TMenuOptionLink>`
  width: ${({ isOpen, widthCollapsed, widthExpanded }) =>
    isOpen ? widthExpanded : widthCollapsed};
  /* height: 37px; */
  background: ${({ bgColor }) => bgColor || '#fff'};
  padding: 10px 15px 10px 25px;
  :hover {
    cursor: pointer;
    background: ${({ bgColor }) => bgColor || '#eee'};
  }
`;

// componente footer del aside
// const Footer = styled.div`
//   width: 100%;
//   height: 50px;
//   background: #fff;
//   position: absolute;
//   bottom: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

export const NewAsideV: React.FC<TNewAside> = ({
  isOpen,
  data,
  headerType,
  drawerOpen,
  toggleAside,
  handleDrawerOpen,
  gradientAside,
  loadingMenu,
  layoutType,
  closeExpansions,
  closeAllExpansion,
  auditor,
}: TNewAside) => {
  const theme = useTheme();

  const styles = {
    subTitle: {
      fontSize: '0.8em',
      textAlign: 'left',
      width: '100%',
      color: theme.palette.primary.main,
    },
    subTitleModule: {
      fontWeight: 'regular',
      fontSize: '0.8em',
      textAlign: 'left',
      width: '100%',
      color: '#fff',
    },
    icon: {
      fontSize: isOpen ? '20px' : '24px',
      color: '#fff',
      marginTop: '6px',
    },
    iconDot: {
      fontSize: '14px',
      color: '#fff',
      marginTop: '5px',
    },
  };

  const SM = {
    1: 'Parámetros',
    2: 'Procesos',
    3: 'Reportes',
    4: 'Características',
    5: 'Utilitarios',
  };

  const newData = (data as any)?.reduce((prev, current) => {
    // Si no es auditor, excluir operadores de libranza
    if (current.mId === '13' && auditor === 0) {
      return prev;
    }

    const { subMenus } = current;
    const subModulesData = {};
    const newSubMenus = [...subMenus].sort((a, b) => (a?.smPriority || 0) - (b?.smPriority || 0));
    for (let i = 0; i < (newSubMenus || []).length; i++) {
      const subMenu = subMenus[i];
      const sm = subMenu?.smModule;

      if (subModulesData[sm]?.length) {
        subModulesData[sm] = [...subModulesData[sm], subMenu];
      } else {
        subModulesData[sm] = [subMenu];
      }
    }

    return [...prev, { ...current, modules: subModulesData }];
  }, []);

  return (
    <Aside
      isOpen={isOpen}
      marginTop={headerType === 3 && layoutType !== 2 ? '15px' : '0px'}
      widthCollapsed="80px" /* Anchura del aside cuando está oculto */
      widthExpanded="245px" /* Anchura del aside cuando está visible */
      bgColor={
        gradientAside
          ? `linear-gradient(150deg, ${theme.palette.primary.dark}  0%, ${theme.palette.primary.dark}  45%, ${theme.palette.primary.main} 96%)`
          : theme.palette.primary.dark
      }>
      <div
        style={{
          display: 'flex',
          padding: '3px',
          flexDirection: 'column',
        }}>
        {!loadingMenu && newData?.length
          ? newData?.map((x: any, indexX: number) => {
              const key = `keyX-${indexX}`;
              const { modules } = x;
              const modulesData = Object.keys(modules);

              return (
                <MenuOptionV
                  key={key}
                  closeExpansions={closeExpansions}
                  closeAllExpansion={closeAllExpansion}
                  toggleAside={toggleAside}
                  colorBg={
                    gradientAside
                      ? `linear-gradient(150deg, ${theme.palette.primary.dark}  0%, ${theme.palette.primary.main}  45%, ${theme.palette.primary.main} 96%)`
                      : theme.palette.primary.main
                  }
                  textColor="#fff"
                  title={isOpen ? x?.mName : ''}
                  titlePopper={x?.mName}
                  isOpen={isOpen}
                  icon={
                    (x?.mName === 'INICIO' && <Home style={styles.icon} />) ||
                    (x?.mName === 'OPERADORES DE LIBRANZA' && (
                      <BusinessCenter style={styles.icon} />
                    )) ||
                    (x?.mName === 'PAGADURÍAS' && <BusinessIcon style={styles.icon} />) ||
                    (x?.mName === 'INVERSIONISTAS' && <CardMembershipIcon style={styles.icon} />) ||
                    (x?.mName === 'ETAPAS' && <LabelIcon style={styles.icon} />) ||
                    (x?.mName === 'CRÉDITOS' && <RequestQuoteIcon style={styles.icon} />) ||
                    (x?.mName === 'TESORERÍA' && <AccountBalanceIcon style={styles.icon} />) ||
                    (x?.mName === 'BENEFICIARIOS' && <GroupsIcon style={styles.icon} />) ||
                    (x?.mName === 'COMERCIALES' && <FolderSharedIcon style={styles.icon} />) ||
                    (x?.mName === 'USUARIOS' && <PeopleAltIcon style={styles.icon} />) ||
                    (x?.mName === 'CARACTERÍSTICAS' && <ScatterPlotIcon style={styles.icon} />) ||
                    (x?.mName === 'UTILITARIOS' && <HomeRepairServiceIcon style={styles.icon} />) ||
                    (x?.mName === 'CARTERA' && <AccountBalanceWalletIcon style={styles.icon} />) ||
                    (x?.mName === 'PARÁMETROS' && <PlumbingIcon style={styles.icon} />) ||
                    (x?.mName === 'LOTES' && <WidgetsIcon style={styles.icon} />) ||
                    (x?.mName === 'ADMINISTRACIÓN' && (
                      <AdminPanelSettingsIcon style={styles.icon} />
                    )) ||
                    (x?.mName === 'PAGOS ELECTRÓNICOS' && <DvrIcon style={styles.icon} />) ||
                    (x?.mName === 'CRÉDITOS 360' && <StoreIcon style={styles.icon} />)
                  }>
                  {modulesData?.map((mod) => {
                    const moKey = `${mod}_${indexX}`;
                    return (
                      <MenuOptionV
                        key={moKey}
                        isOpen={isOpen}
                        closeExpansions={closeExpansions}
                        closeAllExpansion={closeAllExpansion}
                        toggleAside={toggleAside}
                        colorBg={theme.palette.primary.light}
                        textColor={theme.palette.primary.main}
                        title={SM[mod]}
                        principalMargin="0">
                        {modules[mod]?.length
                          ? modules[mod]?.map((sm, ism) => {
                              const smKey = `${mod}_${ism}`;
                              return (
                                <Link
                                  to={sm.smCode}
                                  style={{
                                    textDecoration: 'none',
                                  }}
                                  key={smKey}>
                                  <MenuOptionLink>
                                    <Typography sx={styles.subTitle}>{sm?.smName}</Typography>
                                  </MenuOptionLink>
                                </Link>
                              );
                            })
                          : null}
                      </MenuOptionV>
                    );
                  })}
                </MenuOptionV>
              );
            })
          : null}
        {loadingMenu
          ? [...Array(9)].map((_x, i) => (
              <Skeleton
                key={`skeleton-${i * 2}`}
                variant="rectangular"
                width="100%"
                height="40px"
                sx={{ backgroundColor: theme.palette.primary.main }}
              />
            ))
          : null}
      </div>
      <Drawer
        open={drawerOpen}
        onClose={() => handleDrawerOpen()}
        sx={{
          width: '270px',
          flexShrink: 0,
          left: '270px',
          '& .MuiDrawer-paper': {
            left: '270px',
            width: '270px',
            top: '80px',
            zIndex: 2,
            backgroundColor: '#fff',
          },
        }}>
        as
      </Drawer>
    </Aside>
  );
};
