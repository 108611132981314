import { selectClientName } from '../../../../common/utils';
import { TImages, TName } from './types.d';

// const client = 'mili'
const client =
  selectClientName().toLowerCase() === 'local' || selectClientName().toLowerCase() === 'miliqa'
    ? 'mili'
    : selectClientName().toLowerCase();

const img: TImages = {
  logo: `https://imagesmilii.s3.amazonaws.com/${client}/logo.svg`,
  fondoLogin: `https://imagesmilii.s3.amazonaws.com/${client}/Login-Fondo.svg`,
  loading: `https://imagesmilii.s3.amazonaws.com/${client}/logoM.svg`,
  logoPdf: `https://imagesmilii.s3.amazonaws.com/${client}/1.jpg`,
  footerPdf: `https://imagesmilii.s3.amazonaws.com/${client}/footerPdf.png`,
  headerPdf: `https://imagesmilii.s3.amazonaws.com/${client}/headerPdf.png`,
  logoM: `https://imagesmilii.s3.amazonaws.com/${client}/logoM.svg`,
  marca: `https://imagesmilii.s3.amazonaws.com/${client}/marca.png`,
  paperPlane: 'https://images.mili.com.co/wp-content/uploads/mili-paper-plane.png',
  eslogan: `https://imagesmilii.s3.amazonaws.com/${client}/eslogan.png`,
  header: `https://imagesmilii.s3.amazonaws.com/${client}/header.svg`,
};

const image = (name: TName) => img[name];
export default image;
